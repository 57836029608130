import React from "react"
import resultData from "../../data/resultData.json";
import './css/ResultContents.css'
import ResultCollaboration from "./ResultCollaboration";

function ResultContents({mombti}) {
    const arr = resultData[mombti].body.split("//")

    const body = []
    for (const idx in arr) {

        if (idx > 0) {
            body.push(<br></br>);
        }

        body.push(arr[idx])
    }


    return (
        <div id="result-contents-main">
            <div id="result-contents-summery-title">
                3줄 요약으로 알아보는 나
            </div>
            <div id="result-contents-summery">
                • {resultData[mombti].summery[0]}<br/>
                • {resultData[mombti].summery[1]}<br/>
                • {resultData[mombti].summery[2]}<br/>
            </div>
            <div id="result-contents-body-title">
                이 몸을 가지게 된 나의 특징은?!
            </div>
            <div id="result-contents-body-info">
                {body}

                <div id="result-page-rate">
                    <div id="result-page-rate-title">
                        우리나라에서 발견될 확률
                    </div>

                    <div id="result-page-rate-info">
                        {resultData[mombti].rate}
                    </div>

                    <ResultCollaboration mombti={mombti}
                                         goodMbti={resultData[mombti].goodCollaboration}
                                         badMbti={resultData[mombti].badCollaboration}/>
                </div>
            </div>
        </div>
    )
}

export default ResultContents;