import React, {useEffect} from "react"
import LoadingLoof from "../../image/loadingLoof.gif";
import {useHistory, useLocation} from "react-router";

import mappingData from "../../data/mappingData.json";
import "./LoadingPage.css"
import ReactGA from "react-ga";
import Logo from "../../image/medipass_logo.png";

function LoadingPage() {
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        ReactGA.pageview(window.location.pathname);

        if (!location.answers) {
            return;
        }

        let a = location.answers[0] + location.answers[4] + location.answers[8];
        let b = location.answers[1] + location.answers[5] + location.answers[9]
        let c = location.answers[2] + location.answers[6] + location.answers[10]
        let d = location.answers[3] + location.answers[7] + location.answers[11]

        let mbti = '';

        if (a > 1) {
            mbti += 'I'
        } else {
            mbti += 'E'
        }

        if (b > 1) {
            mbti += 'N'
        } else {
            mbti += 'S'
        }

        if (c > 1) {
            mbti += 'F'
        } else {
            mbti += 'T'
        }

        if (d > 1) {
            mbti += 'P'
        } else {
            mbti += 'J'
        }

        var mombti = mappingData[mbti];

        setTimeout(() => history.push({
            pathname: "/result/" + mombti
        }), 3000)
    }, []);

    return (
        <div id="loading-page-container">
            <div id="loading-title">
                내가 어떤 신체부위인지<br/>
                찾고 있어요.
            </div>
            <div>
                <img id="loading-img"
                     src={LoadingLoof} alt="LoadingLoof"/>
            </div>

            <div id="loading-logo">
                <img id="loading-logo-img" src={Logo} alt="LOGO"/>
            </div>

        </div>
    );
}

export default LoadingPage;