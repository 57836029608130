import React, {useEffect} from "react"
import Logo from "../../image/medipass_logo_old.png";
import {useParams} from "react-router";
import resultData from "../../data/resultData.json";


import ResultUpper from "./ResultUpper";
import ResultContents from "./ResultContents"


import './css/ResultPage.css'

import ReactGA from "react-ga";
import Meta from "../Meta";
import {ExternalLink} from "react-external-link";

function ResultPage() {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, []);

    let {mombti} = useParams();

    const metaData = {
        image: resultData[mombti].ogImagePath
    }

    const logoClick = () => {
        ReactGA.event({
            category: 'logo',
            action: 'click',
            label: window.location.pathname
        });
    }

    return (
        <div id="result-page-container">
            <Meta data={metaData}/>

            <ExternalLink href="https://web.medipass.me" onClick={() => logoClick()}>
                <div style={{
                    position: 'absolute',
                    left: '120px',
                    top: '29px'
                }}>
                    <img style={{
                        width: '120px',
                        height: '22.55px',
                    }}
                         src={Logo} alt="LOGO"/>
                </div>
            </ExternalLink>

            <ResultUpper mombti={mombti}/>

            <ResultContents mombti={mombti}/>

        </div>
    );
}


export default ResultPage;