import React from "react"
import './css/ResultShare.css'
import copyImg from '../../image/copy.png';
import shareKakao from '../../image/share_kakao.png';

import copy from 'copy-to-clipboard';
import useScript from "../utils/useScript";
import ResultLower from "./ResultLower";
import * as ReactGA from "react-ga";


function ResultShare({kakaoTemplateId}) {
    useScript('https://developers.kakao.com/sdk/js/kakao.js')

    const copyToClipboard = () => {
        copy(window.location.href);

        ReactGA.event({
            category: 'share',
            action: 'copy',
            label: window.location.pathname
        });

        alert("링크가 복사 되었습니다.")
    }

    function kakaoBtnClick() {
        if (window.Kakao) {
            const kakao = window.Kakao

            if (!kakao.isInitialized()) {
                kakao.init(process.env.REACT_APP_KAKAO_KEY)
            }

            ReactGA.event({
                category: 'share',
                action: 'kakao',
                label: window.location.pathname
            });

            kakao.Link.sendCustom({
                templateId: kakaoTemplateId
            });
        }
    }

    return (
        <div>
            <div id="result-share-container">
                <div id='result-share-title'>
                    내 환장 콜라보 찾고 혼쭐내주기
                </div>

                <div id='result-share-btn' onClick={() => copyToClipboard()}>
                    <img id="result-share-img" src={copyImg} alt="copy"/>
                </div>

                <div id='result-share-kakao-btn' onClick={() => kakaoBtnClick()}>
                    <img id="result-share-kakao-img" src={shareKakao} alt="kakao"/>
                </div>

                <ResultLower/>
            </div>


        </div>

    );
}

export default ResultShare;