import React from "react"
import resultData from "../../data/resultData.json";
import './css/ResultCollaboration.css'
import ResultShare from "./ResultShare";

function ResultCollaboration({mombti, goodMbti, badMbti}) {

    return (
        <div id="collaboration-container">
            <div id="collaboration-main">
                <div id="collaboration-sub1">
                    <img id='collaboration-img'
                         src={resultData[goodMbti].thumbPath} alt="collaboration"/>

                    <div id='collaboration-text'>
                        환상 콜라보
                    </div>

                    <div id='collaboration-title'>
                        {resultData[goodMbti].title.split("//")[0]}<br/>
                        {resultData[goodMbti].title.split("//")[1]}
                    </div>

                    <div id='collaboration-tag1'>
                        {resultData[goodMbti].tag[0]}
                    </div>
                    <div id='collaboration-tag2'>
                        {resultData[goodMbti].tag[1]}
                    </div>
                </div>

                <div id="collaboration-sub2">
                    <img id='collaboration-img'
                         src={resultData[badMbti].thumbPath} alt="collaboration"/>

                    <div id='collaboration-text'>
                        환장 콜라보
                    </div>

                    <div id='collaboration-title'>
                        {resultData[badMbti].title.split("//")[0]}<br/>
                        {resultData[badMbti].title.split("//")[1]}
                    </div>

                    <div id='collaboration-tag1'>
                        {resultData[badMbti].tag[0]}
                    </div>
                    <div id='collaboration-tag2'>
                        {resultData[badMbti].tag[1]}
                    </div>
                </div>

                <ResultShare kakaoTemplateId={resultData[mombti].kakaoTemplateId}></ResultShare>
            </div>
        </div>


    );
}

export default ResultCollaboration;