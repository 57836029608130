import React from "react"
import './css/QuestionUpper.css'

function QuestionUpper({count, allCount, slider}) {

    return (
        <div>
            <div style={{
                position: 'absolute',
                width: '55px',
                height: '48px',
                left: '16px',
                top: '48px',
                fontFamilly: 'Spoqa Han Sans Neo',
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '40px',
                lineHeight: '48px',
                color: '#6328E5'
            }}>
                Q{count}
            </div>
            <div style={{
                position: 'absolute',
                width: '44px',
                height: '30px',
                left: '300px',
                top: '57px',
                fontFamilly: 'Spoqa Han Sans Neo',
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '20px',
                lineHeight: 'right',
                color: '#C4C4C4'
            }}>
                {count}/{allCount}
            </div>

            <div id="rectangle"></div>
            <div id="inner-rectangle" style={{width: slider}}>
            </div>
        </div>
    );
}

export default QuestionUpper;