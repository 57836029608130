import React from "react"
import './css/QuestionAnswer.css'
import questionData from "../../data/questionData.json";

function QuestionUpper({count, index}) {
    const answer = []
    const answerArr = questionData.questions[count].answer[index].split("//");

    let textStyle = answerArr.length > 1 ? "question-answer-text1" : "question-answer-text2"

    for (const idx in answerArr) {
        if (idx > 0) {
            answer.push(<br></br>);
        }

        answer.push(answerArr[idx])
    }

    return (
        <div>
            <div id={textStyle}>{answer}</div>
        </div>
    );
}

export default QuestionUpper;