import React from 'react';
import {Helmet} from 'react-helmet';

const Meta = ({data}) => {
    const image = data.image !== undefined && `${data.image}`;

    return (
        <Helmet titleTemplate="%s">
            {image ? <meta property="og:image" content={image}/> : null}
            {image ? <meta name="twitter:image" content={image}/> : null}
        </Helmet>
    );
};
//[몸BTI 테스트] 저는 .. 【심바의 앞발】 입니다! 당신은 무엇일까요?
export default Meta;