import React from "react"

import './css/ResultLower.css'
import resultLinkData from '../../data/resultLinkData.json'
import {NavLink} from "react-router-dom";
import {ExternalLink} from 'react-external-link';
import * as ReactGA from "react-ga";

function ResultLower() {
    const randomIndex = new Date().getMilliseconds() % 2;

    const againClick = () => {
        ReactGA.event({
            category: 'lower',
            action: 'again',
            label: window.location.pathname
        });
    }

    const downloadClick = () => {
        ReactGA.event({
            category: 'lower',
            action: 'download',
            label: window.location.pathname
        });
    }

    return (
        <div id="result-lower-container">
            <NavLink to="/" onClick={() => againClick()}>
                <div id="result-lower-again-btn">
                    <div id="result-lower-again-text">
                        테스트 다시하기
                    </div>
                </div>
            </NavLink>


            <div id="result-lower-link-btn">
                <ExternalLink href={resultLinkData[randomIndex].link} onClick={() => downloadClick()}>
                    <div id="result-lower-link-text">
                        {resultLinkData[randomIndex].title}
                    </div>
                </ExternalLink>
            </div>


        </div>
    );
}

export default ResultLower;