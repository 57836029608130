import React, {useEffect} from "react"

import StartBtnImg from '../../image/startbutton.png'
import Logo from '../../image/medipass_logo.png'
import './StartPage.css'
import {NavLink} from "react-router-dom"
import Meta from "../Meta";
import {ExternalLink} from "react-external-link";
import * as ReactGA from "react-ga";

function StartPage() {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, []);

    const logoClick = () => {
        ReactGA.event({
            category: 'logo',
            action: 'click',
            label: window.location.pathname
        });
    }

    const metaData = {
        image: "https://what.medipass.me/images/og/default.png"
    }

    return (
        <div id="start-container">
            <Meta data={metaData}/>

            <div id="start-title">
                나는 주인공의<br/>
                OO이었다!?
            </div>
            <div id="start-sub-title">
                어느날 나타난 버튼,<br/>
                누르면 주인공 몸의 일부가 됩니다.
            </div>

            <div id="start-btn">
                <NavLink to="/question">
                    <img id="start-btn-img" src={StartBtnImg} alt="StartBtn"/>
                </NavLink>
            </div>

            <div id="start-logo">
                <ExternalLink href="https://web.medipass.me" onClick={() => logoClick()}>
                    <img id="start-logo-img" src={Logo} alt="LOGO"/>
                </ExternalLink>
            </div>
        </div>
    );
}

export default StartPage;