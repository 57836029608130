import React, {useEffect, useState} from "react"
import {useHistory} from "react-router";

import questionData from "../../data/questionData.json"
import QuestionUpper from "./QuestionUpper";

import QuestionAnswer from "./QuestionAnswer";
import "./css/QuestionPage.css"
import Logo from "../../image/medipass_logo.png";
import ReactGA from "react-ga";
import {ExternalLink} from "react-external-link";

function QuestionPage() {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, []);

    const [count, setCount] = useState(0);
    const [answers] = useState([]);

    const history = useHistory();

    function btnClick(count, answer) {
        answers.push(answer);

        if (count === questionData.questions.length - 1) {
            history.push({
                pathname: "/loading",
                answers: answers
            })
        } else {
            setCount(count + 1);
        }
    }

    let question = []
    const answerArr = questionData.questions[count].question.split("//");

    for (const idx in answerArr) {
        if (idx > 0) {
            question.push(<br></br>);
        }

        question.push(answerArr[idx])
    }

    const logoClick = () => {
        ReactGA.event({
            category: 'logo',
            action: 'click',
            label: window.location.pathname
        });
    }

    return (
        <div id="question-container">
            <QuestionUpper count={count + 1} allCount={questionData.questions.length}
                           slider={328 / questionData.questions.length * (count + 1)}></QuestionUpper>

            <div id="question-body">
                {question}
            </div>

            <div id="question-answer1" onClick={() => btnClick(count, 0)}>
                <QuestionAnswer count={count} index={0}></QuestionAnswer>
            </div>

            <div id="question-answer2" onClick={() => btnClick(count, 1)}>
                <QuestionAnswer count={count} index={1}></QuestionAnswer>
            </div>


            <div id="question-logo">
                <ExternalLink href="https://web.medipass.me" onClick={() => logoClick()}>
                    <img id="question-logo-img" src={Logo} alt="LOGO"/>
                </ExternalLink>
            </div>

        </div>
    );
}

export default QuestionPage;