import React from "react"
import resultData from "../../data/resultData.json";
import './css/ResultUpper.css'

function ResultUpper({mombti}) {

    return (
        <div id="result-upper-main">
            <div id="result-upper-title">
                {resultData[mombti].title.split("//")[0]}<br/>
                {resultData[mombti].title.split("//")[1]}
            </div>

            <img id="result-upper-image" src={resultData[mombti].imagePath} alt="main"/>

            <div id="result-upper-tag1">
                <div id="result-upper-tag_text1">
                    {resultData[mombti].tag[0]}
                </div>
            </div>

            <div id="result-upper-tag2">
                <div id="result-upper-tag_text2">
                    {resultData[mombti].tag[1]}
                </div>
            </div>

        </div>
    );
}

export default ResultUpper;