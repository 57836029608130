import './App.css';
import {Route} from "react-router-dom"
import StartPage from "./components/start/StartPage";
import LoadingPage from "./components/loading/LoadingPage";
import QuestionPage from "./components/question/QuestionPage";
import ReactGA from 'react-ga';
import React, {useEffect} from "react";
import ResultPage from "./components/result/ResultPage";


ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);

function App() {

    useEffect(() => {
        if (window.location.hash) {
            window.location.href = window.location.hash.replace('#', '')
        }
    }, []);


    return (
        <>
            <Route exact path="/" component={StartPage}></Route>
            <Route exact path="/question" component={QuestionPage}></Route>
            <Route exact path="/loading" component={LoadingPage}></Route>
            <Route exact path="/result/:mombti" component={ResultPage}></Route>
        </>
    );
}

export default App;
